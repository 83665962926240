/*
 * Translated default messages for the jQuery datepicker plugin.
 * Locale: FR
 */
$.fn.datepicker.dates['fr'] = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jui', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
    today: "Aujourd'hui",
    monthsTitle: "Mois",
    clear: "Clair",
    format: "yyyy-mm-dd",
    titleFormat: "MM yyyy", /* Leverages same syntax as 'format' */
    weekStart: 1
};

/*!
 * FileInput French Translations
 *
 * This file must be loaded after 'fileinput.js'. Patterns in braces '{}', or
 * any HTML markup tags in the messages must not be converted or translated.
 *
 * @see http://github.com/kartik-v/bootstrap-fileinput
 *
 * NOTE: this file must be saved in UTF-8 encoding.
 */
$.fn.fileinputLocales['fr'] = {
    fileSingle: 'fichier',
    filePlural: 'fichiers',
    browseLabel: 'Parcourir&hellip;',
    removeLabel: 'Retirer',
    removeTitle: 'Retirer les fichiers sélectionnés',
    cancelLabel: 'Annuler',
    cancelTitle: "Annuler l'envoi en cours",
    uploadLabel: 'Transférer',
    uploadTitle: 'Transférer les fichiers sélectionnés',
    msgNo: 'Non',
    msgNoFilesSelected: '',
    msgCancelled: 'Annulé',
    msgPlaceholder: 'Sélectionner le(s) {files}...',
    msgZoomModalHeading: 'Aperçu détaillé',
    msgFileRequired: 'Vous devez sélectionner un fichier à uploader.',
    msgSizeTooSmall: 'Le fichier "{name}" (<b>{size} KB</b>) est inférieur à la taille minimale de <b>{minSize} KB</b>.',
    msgSizeTooLarge: 'Le fichier "{name}" (<b>{size} Ko</b>) dépasse la taille maximale autorisée qui est de <b>{maxSize} Ko</b>.',
    msgFilesTooLess: 'Vous devez sélectionner au moins <b>{n}</b> {files} à transmettre.',
    msgFilesTooMany: 'Le nombre de fichier sélectionné <b>({n})</b> dépasse la quantité maximale autorisée qui est de <b>{m}</b>.',
    msgFileNotFound: 'Le fichier "{name}" est introuvable !',
    msgFileSecured: "Des restrictions de sécurité vous empêchent d'accéder au fichier \"{name}\".",
    msgFileNotReadable: 'Le fichier "{name}" est illisible.',
    msgFilePreviewAborted: 'Prévisualisation du fichier "{name}" annulée.',
    msgFilePreviewError: 'Une erreur est survenue lors de la lecture du fichier "{name}".',
    msgInvalidFileName: 'Caractères invalides ou non supportés dans le nom de fichier "{name}".',
    msgInvalidFileType: 'Type de document invalide pour "{name}". Seulement les documents de type "{types}" sont autorisés.',
    msgInvalidFileExtension: 'Extension invalide pour le fichier "{name}". Seules les extensions "{extensions}" sont autorisées.',
    msgFileTypes: {
        'image': 'image',
        'html': 'HTML',
        'text': 'text',
        'video': 'video',
        'audio': 'audio',
        'flash': 'flash',
        'pdf': 'PDF',
        'object': 'object'
    },
    msgUploadAborted: 'Le transfert du fichier a été interrompu',
    msgUploadThreshold: 'En cours...',
    msgUploadBegin: 'Initialisation...',
    msgUploadEnd: 'Terminé',
    msgUploadEmpty: 'Aucune donnée valide disponible pour transmission.',
    msgUploadError: 'Erreur',
    msgValidationError: 'Erreur de validation',
    msgLoading: 'Transmission du fichier {index} sur {files}&hellip;',
    msgProgress: 'Transmission du fichier {index} sur {files} - {name} - {percent}%.',
    msgSelected: '{n} {files} sélectionné(s)',
    msgFoldersNotAllowed: 'Glissez et déposez uniquement des fichiers ! {n} répertoire(s) exclu(s).',
    msgImageWidthSmall: 'La largeur de l\'image "{name}" doit être d\'au moins {size} px.',
    msgImageHeightSmall: 'La hauteur de l\'image "{name}" doit être d\'au moins {size} px.',
    msgImageWidthLarge: 'La largeur de l\'image "{name}" ne peut pas dépasser {size} px.',
    msgImageHeightLarge: 'La hauteur de l\'image "{name}" ne peut pas dépasser {size} px.',
    msgImageResizeError: "Impossible d'obtenir les dimensions de l'image à redimensionner.",
    msgImageResizeException: "Erreur lors du redimensionnement de l'image.<pre>{errors}</pre>",
    msgAjaxError: "Une erreur s'est produite pendant l'opération de {operation}. Veuillez réessayer plus tard.",
    msgAjaxProgressError: 'L\'opération "{operation}" a échoué',
    ajaxOperations: {
        deleteThumb: 'suppression du fichier',
        uploadThumb: 'transfert du fichier',
        uploadBatch: 'transfert des fichiers',
        uploadExtra: 'soumission des données de formulaire'
    },
    dropZoneTitle: 'Glissez et déposez les fichiers ici&hellip;',
    dropZoneClickTitle: '<br>(ou cliquez pour sélectionner manuellement)',
    fileActionSettings: {
        removeTitle: 'Supprimer le fichier',
        uploadTitle: 'Transférer le fichier',
        uploadRetryTitle: 'Relancer le transfert',
        zoomTitle: 'Voir les détails',
        dragTitle: 'Déplacer / Réarranger',
        indicatorNewTitle: 'Pas encore transféré',
        indicatorSuccessTitle: 'Posté',
        indicatorErrorTitle: 'Ajouter erreur',
        indicatorLoadingTitle: 'En cours...'
    },
    previewZoomButtonTitles: {
        prev: 'Voir le fichier précédent',
        next: 'Voir le fichier suivant',
        toggleheader: 'Masquer le titre',
        fullscreen: 'Mode plein écran',
        borderless: 'Mode cinéma',
        close: "Fermer l'aperçu"
    }
};

$.extend($.summernote.lang, {
    'fr': {

        font: {
            bold: 'Gras',
            italic: 'Italique',
            underline: 'Souligné',
            clear: 'Effacer la mise en forme',
            height: 'Interligne',
            name: 'Famille de police',
            strikethrough: 'Barré',
            superscript: 'Exposant',
            subscript: 'Indice',
            size: 'Taille de police'
        },
        image: {
            image: 'Image',
            insert: 'Insérer une image',
            resizeFull: 'Taille originale',
            resizeHalf: 'Redimensionner à 50 %',
            resizeQuarter: 'Redimensionner à 25 %',
            floatLeft: 'Aligné à gauche',
            floatRight: 'Aligné à droite',
            floatNone: 'Pas d\'alignement',
            shapeRounded: 'Forme: Rectangle arrondie',
            shapeCircle: 'Forme: Cercle',
            shapeThumbnail: 'Forme: Vignette',
            shapeNone: 'Forme: Aucune',
            dragImageHere: 'Faites glisser une image ou un texte dans ce cadre',
            dropImage: 'Lachez l\'image ou le texte',
            selectFromFiles: 'Choisir un fichier',
            maximumFileSize: 'Taille de fichier maximale',
            maximumFileSizeError: 'Taille maximale du fichier dépassée',
            url: 'URL de l\'image',
            remove: 'Supprimer l\'image'
        },
        video: {
            video: 'Vidéo',
            videoLink: 'Lien vidéo',
            insert: 'Insérer une vidéo',
            url: 'URL de la vidéo',
            providers: '(YouTube, Vimeo, Vine, Instagram, DailyMotion ou Youku)'
        },
        link: {
            link: 'Lien',
            insert: 'Insérer un lien',
            unlink: 'Supprimer un lien',
            edit: 'Modifier',
            textToDisplay: 'Texte à afficher',
            url: 'URL du lien',
            openInNewWindow: 'Ouvrir dans une nouvelle fenêtre'
        },
        table: {
            table: 'Tableau',
            addRowAbove: 'Ajouter une ligne au-dessus',
            addRowBelow: 'Ajouter une ligne en dessous',
            addColLeft: 'Ajouter une colonne à gauche',
            addColRight: 'Ajouter une colonne à droite',
            delRow: 'Supprimer la ligne',
            delCol: 'Supprimer la colonne',
            delTable: 'Supprimer le tableau'
        },
        hr: {
            insert: 'Insérer une ligne horizontale'
        },
        style: {
            style: 'Style',
            p: 'Normal',
            blockquote: 'Citation',
            pre: 'Code source',
            h1: 'Titre 1',
            h2: 'Titre 2',
            h3: 'Titre 3',
            h4: 'Titre 4',
            h5: 'Titre 5',
            h6: 'Titre 6'
        },
        lists: {
            unordered: 'Liste à puces',
            ordered: 'Liste numérotée'
        },
        options: {
            help: 'Aide',
            fullscreen: 'Plein écran',
            codeview: 'Afficher le code HTML'
        },
        paragraph: {
            paragraph: 'Paragraphe',
            outdent: 'Diminuer le retrait',
            indent: 'Augmenter le retrait',
            left: 'Aligner à gauche',
            center: 'Centrer',
            right: 'Aligner à droite',
            justify: 'Justifier'
        },
        color: {
            recent: 'Dernière couleur sélectionnée',
            more: 'Plus de couleurs',
            background: 'Couleur de fond',
            foreground: 'Couleur de police',
            transparent: 'Transparent',
            setTransparent: 'Définir la transparence',
            reset: 'Restaurer',
            resetToDefault: 'Restaurer la couleur par défaut'
        },
        shortcut: {
            shortcuts: 'Raccourcis',
            close: 'Fermer',
            textFormatting: 'Mise en forme du texte',
            action: 'Action',
            paragraphFormatting: 'Mise en forme des paragraphes',
            documentStyle: 'Style du document',
            extraKeys: 'Touches supplémentaires'
        },
        help: {
            'insertParagraph': 'Insérer paragraphe',
            'undo': 'Défaire la dernière commande',
            'redo': 'Refaire la dernière commande',
            'tab': 'Tabulation',
            'untab': 'Tabulation arrière',
            'bold': 'Mettre en caractère gras',
            'italic': 'Mettre en italique',
            'underline': 'Mettre en souligné',
            'strikethrough': 'Mettre en texte barré',
            'removeFormat': 'Nettoyer les styles',
            'justifyLeft': 'Aligner à gauche',
            'justifyCenter': 'Centrer',
            'justifyRight': 'Aligner à droite',
            'justifyFull': 'Justifier à gauche et à droite',
            'insertUnorderedList': 'Basculer liste à puces',
            'insertOrderedList': 'Basculer liste ordonnée',
            'outdent': 'Diminuer le retrait du paragraphe',
            'indent': 'Augmenter le retrait du paragraphe',
            'formatPara': 'Changer le paragraphe en cours en normal (P)',
            'formatH1': 'Changer le paragraphe en cours en entête H1',
            'formatH2': 'Changer le paragraphe en cours en entête H2',
            'formatH3': 'Changer le paragraphe en cours en entête H3',
            'formatH4': 'Changer le paragraphe en cours en entête H4',
            'formatH5': 'Changer le paragraphe en cours en entête H5',
            'formatH6': 'Changer le paragraphe en cours en entête H6',
            'insertHorizontalRule': 'Insérer séparation horizontale',
            'linkDialog.show': 'Afficher fenêtre d\'hyperlien'
        },
        history: {
            undo: 'Annuler la dernière action',
            redo: 'Restaurer la dernière action annulée'
        },
        specialChar: {
            specialChar: 'CARACTÈRES SPÉCIAUX',
            select: 'Choisir des caractères spéciaux'
        }
    }
});
