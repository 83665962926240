jQuery.validator.addMethod("notEqualTo",
    function(value, element, param) {
        var notEqual = true;
        value = $.trim(value);
        for (i = 0; i < param.length; i++) {
            if (value == $.trim($(param[i]).val())) { notEqual = false; }
        }
        return this.optional(element) || notEqual;
    },
    "Please enter a different value."
);

/*jQuery.validator.addClassRules({
    required: {
        required: true
    }
});*/

/**
 * Form remote submit
 */
$(document).on('turbo:load', function () {
    Gymits.registerFormEvents();

    // Toolbox toggle state
    $('.ibox-tools.save-state > .collapse-link').click(function () {
        // inspinia.js function
        if (localStorageSupport()) {

        }
    });
});
