/*
 * Translated default messages for the jQuery datepicker plugin.
 * Locale: PT (Portuguese; português)
 */
$.fn.datepicker.dates['pt'] = {
    days: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
    daysShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    daysMin: ["Do", "Se", "Te", "Qu", "Qu", "Se", "Sa"],
    months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
    monthsShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    today: "Hoje",
    monthsTitle: "Meses",
    clear: "Limpar",
    format: "yyyy-mm-dd",
    titleFormat: "MM yyyy", /* Leverages same syntax as 'format' */
    weekStart: 1
};

/*!
 * FileInput Portuguese Translations
 *
 * This file must be loaded after 'fileinput.js'. Patterns in braces '{}', or
 * any HTML markup tags in the messages must not be converted or translated.
 *
 * @see http://github.com/kartik-v/bootstrap-fileinput
 *
 * NOTE: this file must be saved in UTF-8 encoding.
 */
$.fn.fileinputLocales['pt'] = {
    fileSingle: 'ficheiro',
    filePlural: 'ficheiros',
    browseLabel: 'Procurar &hellip;',
    removeLabel: 'Remover',
    removeTitle: 'Remover ficheiros seleccionados',
    cancelLabel: 'Cancelar',
    cancelTitle: 'Abortar carregamento ',
    uploadLabel: 'Carregar',
    uploadTitle: 'Carregar ficheiros seleccionados',
    msgNo: 'Não',
    msgNoFilesSelected: '',
    msgCancelled: 'Cancelado',
    msgZoomModalHeading: 'Pré-visualização detalhada',
    msgSizeTooLarge: 'Ficheiro "{name}" (<b>{size} KB</b>) excede o tamanho máximo permido de <b>{maxSize} KB</b>.',
    msgFilesTooLess: 'Deve seleccionar pelo menos <b>{n}</b> {files} para fazer upload.',
    msgFilesTooMany: 'Número máximo de ficheiros seleccionados <b>({n})</b> excede o limite máximo de <b>{m}</b>.',
    msgFileNotFound: 'Ficheiro "{name}" não encontrado!',
    msgFileSecured: 'Restrições de segurança preventem a leitura do ficheiro "{name}".',
    msgFileNotReadable: 'Ficheiro "{name}" não pode ser lido.',
    msgFilePreviewAborted: 'Pré-visualização abortado para o ficheiro "{name}".',
    msgFilePreviewError: 'Ocorreu um erro ao ler o ficheiro "{name}".',
    msgInvalidFileType: 'Tipo inválido para o ficheiro "{name}". Apenas ficheiros "{types}" são suportados.',
    msgInvalidFileExtension: 'Extensão inválida para o ficheiro "{name}". Apenas ficheiros "{extensions}" são suportados.',
    msgUploadAborted: 'O upload do arquivo foi abortada',
    msgUploadThreshold: 'Processing...',
    msgValidationError: 'Erro de validação',
    msgLoading: 'A carregar ficheiro {index} de {files} &hellip;',
    msgProgress: 'A carregar ficheiro {index} de {files} - {name} - {percent}% completo.',
    msgSelected: '{n} {files} seleccionados',
    msgFoldersNotAllowed: 'Arrastar e largar ficheiros apenas! {n} pasta(s) ignoradas.',
    msgImageWidthSmall: 'Largura do arquivo de imagem "{name}" deve ser pelo menos {size} px.',
    msgImageHeightSmall: 'Altura do arquivo de imagem "{name}" deve ser pelo menos {size} px.',
    msgImageWidthLarge: 'Largura do arquivo de imagem "{name}" não pode exceder {size} px.',
    msgImageHeightLarge: 'Altura do arquivo de imagem "{name}" não pode exceder {size} px.',
    msgImageResizeError: 'Could not get the image dimensions to resize.',
    msgImageResizeException: 'Erro ao redimensionar a imagem.<pre>{errors}</pre>',
    dropZoneTitle: 'Arrastar e largar ficheiros aqui &hellip;',
    dropZoneClickTitle: '<br>(or click to select {files})',
    fileActionSettings: {
        removeTitle: 'Remover arquivo',
        uploadTitle: 'Carregar arquivo',
        zoomTitle: 'Ver detalhes',
        dragTitle: 'Move / Rearrange',
        indicatorNewTitle: 'Ainda não carregou',
        indicatorSuccessTitle: 'Carregado',
        indicatorErrorTitle: 'Carregar Erro',
        indicatorLoadingTitle: 'A carregar ...'
    },
    previewZoomButtonTitles: {
        prev: 'View previous file',
        next: 'View next file',
        toggleheader: 'Toggle header',
        fullscreen: 'Toggle full screen',
        borderless: 'Toggle borderless mode',
        close: 'Close detailed preview'
    }
};

$.extend($.summernote.lang, {
    'pt': {
        font: {
            bold: 'Negrito',
            italic: 'Itálico',
            underline: 'Sublinhado',
            clear: 'Remover estilo da fonte',
            height: 'Altura da linha',
            name: 'Fonte',
            strikethrough: 'Riscado',
            size: 'Tamanho da fonte'
        },
        image: {
            image: 'Imagem',
            insert: 'Inserir imagem',
            resizeFull: 'Redimensionar Completamente',
            resizeHalf: 'Redimensionar pela Metade',
            resizeQuarter: 'Redimensionar um Quarto',
            floatLeft: 'Flutuar para Esquerda',
            floatRight: 'Flutuar para Direira',
            floatNone: 'Não Flutuar',
            dragImageHere: 'Arraste uma imagem para cá',
            selectFromFiles: 'Selecione a partir dos arquivos',
            url: 'URL da imagem'
        },
        video: {
            video: 'Vídeo',
            videoLink: 'Link para vídeo',
            insert: 'Inserir vídeo',
            url: 'URL do vídeo?',
            providers: '(YouTube, Vimeo, Vine, Instagram, DailyMotion ou Youku)'
        },
        link: {
            link: 'Link',
            insert: 'Inserir link',
            unlink: 'Remover link',
            edit: 'Editar',
            textToDisplay: 'Texto para exibir',
            url: 'Para qual URL esse link leva?',
            openInNewWindow: 'Abrir em uma nova janela'
        },
        table: {
            table: 'Tabela'
        },
        hr: {
            insert: 'Inserir linha horizontal'
        },
        style: {
            style: 'Estilo',
            normal: 'Normal',
            blockquote: 'Citação',
            pre: 'Código',
            h1: 'Título 1',
            h2: 'Título 2',
            h3: 'Título 3',
            h4: 'Título 4',
            h5: 'Título 5',
            h6: 'Título 6'
        },
        lists: {
            unordered: 'Lista com marcadores',
            ordered: 'Lista numerada'
        },
        options: {
            help: 'Ajuda',
            fullscreen: 'Tela cheia',
            codeview: 'Ver código-fonte'
        },
        paragraph: {
            paragraph: 'Parágrafo',
            outdent: 'Menor tabulação',
            indent: 'Maior tabulação',
            left: 'Alinhar à esquerda',
            center: 'Alinhar ao centro',
            right: 'Alinha à direita',
            justify: 'Justificado'
        },
        color: {
            recent: 'Cor recente',
            more: 'Mais cores',
            background: 'Fundo',
            foreground: 'Fonte',
            transparent: 'Transparente',
            setTransparent: 'Fundo transparente',
            reset: 'Restaurar',
            resetToDefault: 'Restaurar padrão'
        },
        shortcut: {
            shortcuts: 'Atalhos do teclado',
            close: 'Fechar',
            textFormatting: 'Formatação de texto',
            action: 'Ação',
            paragraphFormatting: 'Formatação de parágrafo',
            documentStyle: 'Estilo de documento'
        },
        history: {
            undo: 'Desfazer',
            redo: 'Refazer'
        },
        help: {
            'insertParagraph': 'Inserir Parágrafo',
            'undo': 'Desfazer o último comando',
            'redo': 'Refazer o último comando',
            'tab': 'Tab',
            'untab': 'Desfazer tab',
            'bold': 'Colocar em negrito',
            'italic': 'Colocar em itálico',
            'underline': 'Sublinhado',
            'strikethrough': 'Tachado',
            'removeFormat': 'Remover estilo',
            'justifyLeft': 'Alinhar à esquerda',
            'justifyCenter': 'Centralizar',
            'justifyRight': 'Alinhar à esquerda',
            'justifyFull': 'Justificar',
            'insertUnorderedList': 'Lista não ordenada',
            'insertOrderedList': 'Lista ordenada',
            'outdent': 'Recuar parágrafo atual',
            'indent': 'Avançar parágrafo atual',
            'formatPara': 'Alterar formato do bloco para parágrafo(tag P)',
            'formatH1': 'Alterar formato do bloco para H1',
            'formatH2': 'Alterar formato do bloco para H2',
            'formatH3': 'Alterar formato do bloco para H3',
            'formatH4': 'Alterar formato do bloco para H4',
            'formatH5': 'Alterar formato do bloco para H5',
            'formatH6': 'Alterar formato do bloco para H6',
            'insertHorizontalRule': 'Inserir régua horizontal',
            'linkDialog.show': 'Inserir um Hiperlink'
        }
    }
});
