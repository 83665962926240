/*
 * Translated default messages for the jQuery datepicker plugin.
 * Locale: ES (Spanish; Español)
 */
$.fn.datepicker.dates['es'] = {
    days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
    daysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    daysMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
    months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    today: "Hoy",
    monthsTitle: "Meses",
    clear: "Borrar",
    format: "yyyy-mm-dd",
    titleFormat: "MM yyyy", /* Leverages same syntax as 'format' */
    weekStart: 1
};

/*!
 * FileInput Spanish Translations
 *
 * This file must be loaded after 'fileinput.js'. Patterns in braces '{}', or
 * any HTML markup tags in the messages must not be converted or translated.
 *
 * @see http://github.com/kartik-v/bootstrap-fileinput
 *
 * NOTE: this file must be saved in UTF-8 encoding.
 */
$.fn.fileinputLocales['es'] = {
    fileSingle: 'archivo',
    filePlural: 'archivos',
    browseLabel: 'Examinar &hellip;',
    removeLabel: 'Quitar',
    removeTitle: 'Quitar archivos seleccionados',
    cancelLabel: 'Cancelar',
    cancelTitle: 'Abortar la subida en curso',
    uploadLabel: 'Subir archivo',
    uploadTitle: 'Subir archivos seleccionados',
    msgNo: 'No',
    msgNoFilesSelected: '',
    msgCancelled: 'Cancelado',
    msgZoomModalHeading: 'Vista previa detallada',
    msgSizeTooLarge: 'Archivo "{name}" (<b>{size} KB</b>) excede el tamaño máximo permitido de <b>{maxSize} KB</b>.',
    msgFilesTooLess: 'Debe seleccionar al menos <b>{n}</b> {files} a cargar.',
    msgFilesTooMany: 'El número de archivos seleccionados a cargar <b>({n})</b> excede el límite máximo permitido de <b>{m}</b>.',
    msgFileNotFound: 'Archivo "{name}" no encontrado.',
    msgFileSecured: 'No es posible acceder al archivo "{name}" porque estará siendo usado por otra aplicación o no tengamos permisos de lectura.',
    msgFileNotReadable: 'No es posible acceder al archivo "{name}".',
    msgFilePreviewAborted: 'Previsualización del archivo "{name}" cancelada.',
    msgFilePreviewError: 'Ocurrió un error mientras se leía el archivo "{name}".',
    msgInvalidFileType: 'Tipo de archivo no válido para "{name}". Sólo archivos "{types}" son permitidos.',
    msgInvalidFileExtension: 'Extensión de archivo no válido para "{name}". Sólo archivos "{extensions}" son permitidos.',
    msgUploadAborted: 'La carga de archivos se ha cancelado',
    msgValidationError: 'Error de validacion',
    msgLoading: 'Subiendo archivo {index} de {files} &hellip;',
    msgProgress: 'Subiendo archivo {index} de {files} - {name} - {percent}% completado.',
    msgSelected: '{n} {files} seleccionado(s)',
    msgFoldersNotAllowed: 'Arrastre y suelte únicamente archivos. Omitida(s) {n} carpeta(s).',
    msgImageWidthSmall: 'El ancho de la imagen "{name}" debe ser al menos {size} px.',
    msgImageHeightSmall: 'La altura de la imagen "{name}" debe ser al menos {size} px.',
    msgImageWidthLarge: 'El ancho de la imagen "{name}" no puede exceder de {size} px.',
    msgImageHeightLarge: 'La altura de la imagen "{name}" no puede exceder de {size} px.',
    msgImageResizeError: 'No se pudo obtener las dimensiones de imagen para cambiar el tamaño.',
    msgImageResizeException: 'Error al cambiar el tamaño de la imagen.<pre>{errors}</pre>',
    dropZoneTitle: 'Arrastre y suelte aquí los archivos &hellip;',
    dropZoneClickTitle: '<br>(o haga click para seleccionar {files})',
    fileActionSettings: {
        removeTitle: 'Eliminar archivo',
        uploadTitle: 'Subir archivo',
        zoomTitle: 'Ver detalles',
        dragTitle: 'Mover / Arreglar de nuevo',
        indicatorNewTitle: 'No subido todavía',
        indicatorSuccessTitle: 'Subido',
        indicatorErrorTitle: 'Subir Error',
        indicatorLoadingTitle: 'Subiendo ...'
    },
    previewZoomButtonTitles: {
        prev: 'Ver archivo anterior',
        next: 'Ver archivo siguiente',
        toggleheader: 'Activar encabezado',
        fullscreen: 'Activar pantalla completa',
        borderless: 'Activar el modo sin bordes',
        close: 'Cerrar vista detallada'
    }
};

$.extend($.summernote.lang, {
    'es': {
        font: {
            bold: 'Negrita',
            italic: 'Cursiva',
            underline: 'Subrayado',
            clear: 'Quitar estilo de fuente',
            height: 'Altura de línea',
            name: 'Fuente',
            strikethrough: 'Tachado',
            superscript: 'Superíndice',
            subscript: 'Subíndice',
            size: 'Tamaño de la fuente'
        },
        image: {
            image: 'Imagen',
            insert: 'Insertar imagen',
            resizeFull: 'Redimensionar a tamaño completo',
            resizeHalf: 'Redimensionar a la mitad',
            resizeQuarter: 'Redimensionar a un cuarto',
            floatLeft: 'Flotar a la izquierda',
            floatRight: 'Flotar a la derecha',
            floatNone: 'No flotar',
            shapeRounded: 'Forma: Redondeado',
            shapeCircle: 'Forma: Círculo',
            shapeThumbnail: 'Forma: Marco',
            shapeNone: 'Forma: Ninguna',
            dragImageHere: 'Arrastrar una imagen o texto aquí',
            dropImage: 'Suelta la imagen o texto',
            selectFromFiles: 'Seleccionar desde los archivos',
            maximumFileSize: 'Tamaño máximo del archivo',
            maximumFileSizeError: 'Has superado el tamaño máximo del archivo.',
            url: 'URL de la imagen',
            remove: 'Eliminar imagen'
        },
        video: {
            video: 'Vídeo',
            videoLink: 'Link del vídeo',
            insert: 'Insertar vídeo',
            url: '¿URL del vídeo?',
            providers: '(YouTube, Vimeo, Vine, Instagram, DailyMotion o Youku)'
        },
        link: {
            link: 'Link',
            insert: 'Insertar link',
            unlink: 'Quitar link',
            edit: 'Editar',
            textToDisplay: 'Texto para mostrar',
            url: '¿Hacia que URL lleva el link?',
            openInNewWindow: 'Abrir en una nueva ventana'
        },
        table: {
            table: 'Tabla'
        },
        hr: {
            insert: 'Insertar línea horizontal'
        },
        style: {
            style: 'Estilo',
            p: 'p',
            blockquote: 'Cita',
            pre: 'Código',
            h1: 'Título 1',
            h2: 'Título 2',
            h3: 'Título 3',
            h4: 'Título 4',
            h5: 'Título 5',
            h6: 'Título 6'
        },
        lists: {
            unordered: 'Lista desordenada',
            ordered: 'Lista ordenada'
        },
        options: {
            help: 'Ayuda',
            fullscreen: 'Pantalla completa',
            codeview: 'Ver código fuente'
        },
        paragraph: {
            paragraph: 'Párrafo',
            outdent: 'Menos tabulación',
            indent: 'Más tabulación',
            left: 'Alinear a la izquierda',
            center: 'Alinear al centro',
            right: 'Alinear a la derecha',
            justify: 'Justificar'
        },
        color: {
            recent: 'Último color',
            more: 'Más colores',
            background: 'Color de fondo',
            foreground: 'Color de fuente',
            transparent: 'Transparente',
            setTransparent: 'Establecer transparente',
            reset: 'Restaurar',
            resetToDefault: 'Restaurar por defecto'
        },
        shortcut: {
            shortcuts: 'Atajos de teclado',
            close: 'Cerrar',
            textFormatting: 'Formato de texto',
            action: 'Acción',
            paragraphFormatting: 'Formato de párrafo',
            documentStyle: 'Estilo de documento',
            extraKeys: 'Teclas adicionales'
        },
        help : {
            'insertParagraph': 'Insertar párrafo',
            'undo': 'Deshacer última acción',
            'redo': 'Rehacer última acción',
            'tab': 'Tabular',
            'untab': 'Eliminar tabulación',
            'bold': 'Establecer estilo negrita',
            'italic': 'Establecer estilo cursiva',
            'underline': 'Establecer estilo subrayado',
            'strikethrough': 'Establecer estilo tachado',
            'removeFormat': 'Limpiar estilo',
            'justifyLeft': 'Alinear a la izquierda',
            'justifyCenter': 'Alinear al centro',
            'justifyRight': 'Alinear a la derecha',
            'justifyFull': 'Justificar',
            'insertUnorderedList': 'Insertar lista desordenada',
            'insertOrderedList': 'Insertar lista ordenada',
            'outdent': 'Reducir tabulación del párrafo',
            'indent': 'Aumentar tabulación del párrafo',
            'formatPara': 'Cambiar estilo del bloque a párrafo (etiqueta P)',
            'formatH1': 'Cambiar estilo del bloque a H1',
            'formatH2': 'Cambiar estilo del bloque a H2',
            'formatH3': 'Cambiar estilo del bloque a H3',
            'formatH4': 'Cambiar estilo del bloque a H4',
            'formatH5': 'Cambiar estilo del bloque a H5',
            'formatH6': 'Cambiar estilo del bloque a H6',
            'insertHorizontalRule': 'Insertar línea horizontal',
            'linkDialog.show': 'Mostrar panel enlaces'
        },
        history: {
            undo: 'Deshacer',
            redo: 'Rehacer'
        },
        specialChar: {
            specialChar: 'CARACTERES ESPECIALES',
            select: 'Selecciona Caracteres especiales'
        }
    }
});
