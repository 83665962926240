// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Polyfills
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'html5shiv';

import '@hotwired/turbo-rails';

require('jquery');
require('jquery-ujs');

require('../src/remotipart/jquery.remotipart');
require('jquery-ui-dist/jquery-ui');
require('jquery-validation');
require('popper.js');
require('bootstrap');

require('wowjs');
require('pace-progressbar');
require('jquery-slimscroll');
require('metismenu');
require('toastr');
require('icheck');
require('jquery-steps/build/jquery.steps');
require('ekko-lightbox/dist/ekko-lightbox');

require('bootstrap-touchspin');
require('select2');
require('d3');
require('c3');
require('peity');
require('rc-jquery-creditcardvalidator');
require('moment');

require('bootstrap-datepicker');
require('bootstrap-daterangepicker');
require('bootstrap-fileinput');
require('bootstrap-tagsinput');
// require('bootstrap-multiEmail/src/bootstrap-multiEmail');
require('bootstrap-colorpicker');
require('summernote/dist/summernote-bs4');

// 2pay.js is loaded externally
// require('../src/2checkout/2co.min');

require('../src/inspinia/inspinia');

// Gymits
require('expose-loader?exposes=Gymits!../src/gymits');

// Custom
require('../src/initializer');
require('../src/jquery-defaults');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//

require.context('../images', true);
require.context('../images/landing', true);
